<template>
  <div>
    <vue-headful
      title="b_nnah"
      description="Website for an aspiring YouTube content creator, check out my channel and subscribe if you find something entertaining"
    />
    <h1>
      <a
        href="https://www.youtube.com/channel/UCRxaJMI_NReGmIh53QabfFA"
        title="Visit my YouTube channel"
        >b_nnah</a
      >
    </h1>
    <div>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/aU1oQYn0g0o"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div>&nbsp;</div>
    <div
      class="g-ytsubscribe"
      data-channelid="UCRxaJMI_NReGmIh53QabfFA"
      data-layout="full"
      data-theme="dark"
      data-count="hidden"
    ></div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>
