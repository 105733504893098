<template>
  <HelloWorld />
  <div class="stars"></div>
  <div class="stars"></div>
  <div class="stars"></div>
  <div class="stars"></div>
  <div class="stars"></div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>
